
import Component from 'vue-class-component'
import { Emit, Mixins, Prop, Watch } from 'vue-property-decorator'

import ValidationMixin from '@/mixins/ValidationMixin.vue'
import FormMixin from '@/mixins/FormMixin.vue'

import { objectUtils } from '@/utils/object'
import { ThrottleHttp } from '@/apis/Http'
import { SearchLeasObjectApi } from '@/apis/SearchLeasObjectApi'
import _ from 'lodash'
import moment from 'moment'

@Component({
  name: 'SloNewRequestForm'
})

export default class SloNewRequestForm extends Mixins(ValidationMixin, FormMixin) {
  @Prop(Array) carModels!: any
  @Prop(Number) readonly numberOfDealerships!: any
  @Prop(Boolean) readonly isRequiredFieldsFilled!: boolean
  @Prop(Boolean) dealershipLoading: any
  @Emit()
  onChangeBrand(brandId: any) {
    return brandId
  }

  @Emit()
  onChangeCity(cityId: any) {
    return cityId
  }

  @Emit()
  onChangeDistance(distance: any) {
    return distance
  }

  form = {
    carBrandId: null,
    carModel: '',
    cityId: null,
    distance: 100,
    client: ' ',
    comment: '',
    equipment: ' ',
    budget: 0,
    with_mileage: 0,
    is_truck: 0,
    year: undefined,
    mileage: 0
  }

  years = Array(12).fill(moment().format('YYYY')).map((el, index) => el - index)

  carBrands = [
   // { header: 'Популярные марки' }
  ]

  cities = [
    // { header: 'Популярные города' }
  ]

  money = {
    decimal: ',',
    thousands: ' ',
    prefix: '',
    suffix: ' руб.',
    precision: 0,
    masked: false /* doesn't work with directive */
  }

  mileage = {
    decimal: ',',
    thousands: ' ',
    prefix: '',
    suffix: ' км',
    precision: 0,
    masked: false /* doesn't work with directive */
  }

  searchInput = ''
  searchInputModel = ''
  searchCityInput = ''
  citiesMatches = this.cities
  brandMatches = this.carBrands

  @Watch('searchCityInput')
  onSearchCityInputChange(v: string) {
    this.queryCitySelections(v)
  }

  @Watch('searchInput')
  onSearchInputChange(v: string) {
    this.querySelections(v)
    if (!v) {
      this.fetchTenPopularCarBrands()
      this.form.carModel = ''
      this.carModels = []
    }
  }

  @Watch('searchInputModel')
  onSearchInputModelChange(v: string) {
    if (!v) {
      this.form.carModel = ''
    }
  }

  querySelections(v: string) {
    if (v !== null) {
      this.brandMatches = this.carBrands.filter((item: any) => item.name.toLowerCase().startsWith(v.toLowerCase()))
    } else {
      this.brandMatches = this.carBrands
    }
  }

  queryCitySelections(v: string) {
    if (v !== null) {
      this.citiesMatches = this.cities.filter((item: any) => item.name.toLowerCase().startsWith(v.toLowerCase()))
    } else {
      this.citiesMatches = this.cities
    }
  }

  created() {
    this.fetchTenPopularCities()
    this.fetchTenPopularCarBrands()
  }

  get sliderHeight() {
    if (window.innerWidth > 770) {
      return 80
    } else {
      return null
    }
  }

  get sliderLabel() {
    if (window.innerWidth > 770) {
      return 'Расширение радиуса поиска, км'
    } else {
      return ''
    }
  }

  get ticksLabels() {
    if (window.innerWidth > 770) {
      return ['0', '100', '200', '300', '400', '500', '600', '700', '800', '900', '1000']
    } else {
      return ['0', '', '', '', '', '', '', '', '', '', '1000']
    }
  }

  fetchCarBrands(val: string) {
    if (val?.length >= 1) {
      ThrottleHttp.get('/search-leas-object/get-car-brands', {
        params: {
          name: val,
          withMileage: this.form.with_mileage,
          isTruck: this.form.is_truck
        }
      })
        .then((response) => {
          this.carBrands = response.data
          this.form.carModel = ''
          // this.brandMatches = this.carBrands
        })
    }
  }

  fetchTenPopularCarBrands() {
    SearchLeasObjectApi.fetchTenPopularCarBrands(this.form.with_mileage, this.form.is_truck)
      .then(({ data }) => {
        // const popBrands = _.sortBy(data, ['name'])
        this.carBrands = []
        const sorted: any[] = _.sortBy(data, ['name'])
        // @ts-ignore
        this.carBrands.push(...sorted)
        this.brandMatches = this.carBrands
        this.form.carBrandId = null
        this.form.carModel = ''
      })
      .catch((errors) => {
        console.log(errors)
      })
  }

  fetchCities(val: string) {
    if (val?.length >= 1) {
      ThrottleHttp.get('/search-leas-object/get-cities', {
        params: {
          name: val
        }
      })
        .then((response) => {
          this.cities = response.data
          this.citiesMatches = this.cities
        })
    }
  }

  fetchTenPopularCities() {
    SearchLeasObjectApi.fetchTenPopularCities()
      .then(({ data }) => {
        // @ts-ignore
        this.cities.push(...objectUtils.moscowOnTop(data))
        this.citiesMatches = this.cities
      })
      .catch((errors) => {
        console.log(errors)
      })
  }
}
