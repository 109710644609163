
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, PropSync } from 'vue-property-decorator'

@Component({
  name: 'DealershipCard'
})

export default class DealershipCard extends Vue {
  @Prop(Object) dealership!: any
  @PropSync('SelectedDealerships') syncSelectedDealerships!: any

  get cardState() {
    return this.syncSelectedDealerships.indexOf(this.dealership.id) !== -1
      ? 'dealership-card_selected'
      : this.dealership.isFavorite
        ? 'dealership-card_favorite'
        : ' card-dealership_simple'
  }

  get isSelected(): boolean {
    return this.syncSelectedDealerships.indexOf(this.dealership.id) !== -1
  }

  selectDealership(id) {
    const dealershipIndex = this.syncSelectedDealerships.indexOf(id)
    dealershipIndex === -1
      ? this.syncSelectedDealerships.push(id)
      : this.syncSelectedDealerships.splice(dealershipIndex, 1)
  }
}
