
import Vue from 'vue'
import Component from 'vue-class-component'
import _ from 'lodash'

import DealershipCard from '@/views/components/DealershipCard.vue'
import AcquaintanceModal from '@/views/SearchLeasObject/components/AcquaintanceModal.vue'
import SloNewRequestForm from '@/views/SearchLeasObject/components/SloNewRequestForm.vue'

import { UserRoles } from '@/typings/UserTypings'
import { mapGetters } from 'vuex'
import { SearchLeasObjectApi } from '@/apis/SearchLeasObjectApi'
import { Ref } from 'vue-property-decorator'
import { VForm } from '@/mixins/FormMixin.vue'
import { Http } from '@/apis/Http'
import GeoService from '@/apis/GeoService'
import { objectUtils } from '@/utils/object'
import WS from '@/apis/WS'
import TrialDialog from '@/views/SearchLeasObject/components/TrialDialog.vue'
import TrialExpiredDialog from '@/views/SearchLeasObject/components/TrialExpiredDialog.vue'
import { stringUtils } from '@/utils/string'

@Component({
  name: 'SearchLeasObjectNewRequest',
  components: {
    DealershipCard,
    AcquaintanceModal,
    SloNewRequestForm,
    TrialDialog,
    TrialExpiredDialog
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),
    requestLeft() {
      return stringUtils.pluralForm(this.$store.state.auth.requestLeft, ['запрос', 'запроса', 'запросов'])
    },
    trialExpired() {
      return this.$store.state.auth.user.is_trial && !this.$store.state.auth.requestLeft
    }
  }
})

export default class NewSearchRequest extends Vue {
  @Ref() newRequestForm!: VForm

  isSharedContacts = true
  isChecked = true
  selectedDealershipIds = []
  showModal = false
  isDisabledBtn = false
  dealerships = []
  isRequiredFieldsFilled = false

  isValidForm = false

  dealershipLoading = false

  carModels = []
  trialDialog = false
  trialExpiredDialog = false

  get userRole() {
    // @ts-ignore
    return UserRoles[this.user.user_role_id]
  }

  get isDisabled() {
    // @ts-ignore
    return this.isDisabledBtn || !this.isValidForm || this.selectedDealershipIds.length === 0 || !!this.trialExpired
  }

  get btnText(): string {
    return `Отправить (${this.selectedDealershipIds.length})`
  }

  created() {
    // @ts-ignore
    this.$setLoading(false)
    // @ts-ignore
    this.showModal = !this.user.first_name
    Http.get('/user-enter-search-leas-objects-new')
    if (this.$store.state.auth.user.is_trial && this.$store.state.auth.requestLeft && this.$store.state.auth.user.trial_limit !== this.$store.state.auth.requestLeft) {
      this.trialDialog = true
    }
    // @ts-ignore
    if (this.trialExpired) {
      this.trialExpiredDialog = true
    }
  }

  selectDealerships() {
    if (this.isChecked) {
      this.selectedDealershipIds = []
      for (const key in this.dealerships) {
        // @ts-ignore
        if (this.selectedDealershipIds.indexOf(this.dealerships[key].id) === -1) {
          // @ts-ignore
          this.selectedDealershipIds.push(this.dealerships[key].id)
        }
      }
    } else {
      this.selectedDealershipIds = []
    }
  }

  fetchCreatingRequestDetails() {
    SearchLeasObjectApi.fetchCreatingDetails()
      .then((response) => {
        _.assign(this, response.data)
      })
      .finally(() => {
        // @ts-ignore
        this.$setLoading(false)
      })
  }

  sendRequest() {
    console.log(this.newRequestForm.form.year)
    if (this.newRequestForm.form.with_mileage && !this.newRequestForm.form.year) {
      // @ts-ignore
      this.showErrorSystemNotification('Для отправки запроса заполните поле Год выпуска')
      return
    }

    const mileage = parseInt(this.newRequestForm.form.mileage.toString().replace(/\D/g, ''))
    if (this.newRequestForm.form.with_mileage && !mileage) {
      // @ts-ignore
      this.showErrorSystemNotification('Для отправки запроса заполните поле Пробег')
      return
    }

    const budget = parseInt(this.newRequestForm.form.budget.replace(/\D/g, ''))
    if (!budget) {
      // this.showErrorSystemNotification('Для отправки запроса заполните поле бюджет')
      // return
    }

    this.isDisabledBtn = true
    // console.log(this.selectedDealershipIds, this.dealerships)
    Http.post(
      '/search-leas-object/new-request', {
        allChecked: this.isChecked ? 1 : 0,
        showContacts: this.isSharedContacts,
        carBrandId: this.newRequestForm.form.carBrandId,
        cityId: this.newRequestForm.form.cityId,
        comment: this.newRequestForm.form.comment,
        carModel: this.newRequestForm.form.carModel,
        dealershipIds: this.selectedDealershipIds,
        distance: this.newRequestForm.form.distance,
        client: this.newRequestForm.form.client,
        budget: budget,
        with_mileage: this.newRequestForm.form.with_mileage,
        mileage: mileage,
        year: this.newRequestForm.form.year ? this.newRequestForm.form.year : 0,
        is_truck: this.newRequestForm.form.is_truck,
        equipment: this.newRequestForm.form.equipment
      }
    )
      .then(({ data }) => {
        if (data.error) {
          // @ts-ignore
          this.showErrorSystemNotification(data.msg)
          return
        }
        if (data) WS.sendMessage('notification', { type: 'new_request', noAdmins: true, to_user_id: data })
        this.$router.push({ name: 'searchLeasObjectHistoryOfRequests', params: { sended: '1' } })
        this.$store.commit('auth/SET_USER_REQUEST_LEFT', this.$store.state.auth.requestLeft - 1)
      })
      .catch((error) => {
        const msg = this.getErrorMsg(
          error.response.data.errors,
          ['carBrandId', 'carModel', 'cityId', 'comment'],
          'Заполните поля для создания нового запроса'
        )

        // @ts-ignore
        this.showErrorSystemNotification(msg)
      })
      .finally(() => {
        this.isDisabledBtn = false
      })
  }

  getErrorMsg(errors: object, fields: string[], defaultMsg: string): string {
    let result: string
    fields.forEach(function (field) {
      result = (field in errors) ? errors[field][0] : defaultMsg
    })
    // @ts-ignore
    return result
  }

  fetchCarModels(brandId: any) {
    if (brandId > 0) {
      Http.get('/search-leas-object/car-models', {
        params: {
          brandId: brandId,
          withMileage: this.newRequestForm.form.with_mileage,
          isTruck: this.newRequestForm.form.is_truck
        }
      })
        .then(response => {
          this.carModels = objectUtils.sortByName(response.data)
        })
        .catch(() => {
          // @ts-ignore
          this.showErrorSystemNotification('Что-то пошло не так!!!')
        })
    }
  }

  requiredFieldsChecker() {
    if (this.newRequestForm.form.carBrandId !== null && this.newRequestForm.form.cityId !== null) {
      this.isRequiredFieldsFilled = true
      this.getDealershipsList()
    }
  }

  getDealershipsList() {
    this.dealershipLoading = true
    GeoService.getDealerships(this.newRequestForm.form)
      .then(({ data }) => {
        this.dealerships = data.dealerships
        this.selectDealerships()
      })
      .finally(() => { this.dealershipLoading = false })
  }

  onCarBrandChanged(brandId: any) {
    this.requiredFieldsChecker()
    this.fetchCarModels(brandId)
  }
}
