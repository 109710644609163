import { Http } from '@/apis/Http'

export default {
  getDealerships(data: any) {
    return Http.get('/admin/get-dealerships-list', {
      params: {
        data: data
      }
    })
  }
}
