<template>
  <v-card>
    <v-card-title>
      <b style="font-size: 14px">Вы используете пробную версию сервиса</b>
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <p>Вы можете отправить еще <b>{{ requestLeft }}</b></p>
      <p>
        Если Вы хотите использовать сервис без ограничений, сообщите нам об этом письмом на нашу почту
        <a href="mailto:support@poiskpl.ru">support@poiskpl.ru</a>
      </p>
    </v-card-text>
    <v-card-actions class="pb-5">
      <div class="text-center w-100">
        <v-btn color="indigo darken-4" class="white--text pl-10 pr-10" @click="$emit('close')">Закрыть</v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>
<script>
  import { stringUtils } from '@/utils/string'

  export default {
    computed: {
      requestLeft() {
        return stringUtils.pluralForm(this.$store.state.auth.requestLeft, ['запрос', 'запроса', 'запросов'])
      }
    }
  }
</script>
