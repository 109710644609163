
import Component from 'vue-class-component'
import { Mixins, Watch } from 'vue-property-decorator'
import ValidationMixin from '@/mixins/ValidationMixin.vue'
import FormMixin from '@/mixins/FormMixin.vue'

@Component({
  name: 'AcquaintanceForm'
})

export default class AcquaintanceForm extends Mixins(ValidationMixin, FormMixin) {
  @Watch('maskedPhone')
  onMaskedPhoneChanged(val: string) {
    this.form.phone = val.replace(/[^\d]/g, '')
  }

  form = {
    firstName: '',
    phone: ''
  }

  maskedPhone = ''
}
