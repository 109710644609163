
import Vue from 'vue'
import Component from 'vue-class-component'
import AcquaintanceForm from '@/views/Home/components/AcquaintanceForm.vue'
import { UserRoles } from '@/typings/UserTypings'
import { mapGetters } from 'vuex'
import { PropSync, Ref } from 'vue-property-decorator'
import { VForm } from '@/mixins/FormMixin.vue'
import { User } from '@/apis/User'

@Component({
  name: 'AcquaintanceModal',
  components: {
    AcquaintanceForm
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  }
})

export default class AcquaintencaModal extends Vue {
  @Ref() acquaintanceForm!: VForm
  @PropSync('isShowModal', { type: Boolean }) showModal!: boolean
  isModalFormValid = false
  loading = false

  get userRole() {
    // @ts-ignore
    return UserRoles[this.user.user_role_id]
  }

  saveUser() {
    this.loading = true
    User.saveNameAndPhone(this.acquaintanceForm.form)
      .then(response => {
        this.$store.commit('auth/SET_USER', response.data)
        this.showModal = false
      })
      .catch(() => {
        // @ts-ignore
        this.showErrorSystemNotification('Что-то пошло не так! Повторите позже!')
      })
      .finally(() => {
        this.loading = false
      })
  }
}
